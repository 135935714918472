import { memo } from 'react';
import * as S from './RecentlySearchedDestination.styles';
import URL from '@/common/app/utils/urlsUtils';
import { Container } from '@/common/ui/containers/Container/Container';
import { IAttractionsSearch } from '@/common/app/contexts/AttractionsContext';
import { useAppContext, useSearchContext } from '@/common/app/contexts';
import { attractionsUrl } from '@/common/app/utils/urlsUtils';
import { dateQueryString } from '@/common/app/utils/dateUtils';
import { RecentSearchedIcon } from '@/shared/images/icons/RecentSearched';

type Category = {
    id: string;
    count: string;
};

export const RecentlySearchedDestination = () => {
    const { recents } = useSearchContext();
    const { categories: categoriesOptions } = useAppContext();

    if (!recents || !recents.length) {
        return null;
    }

    const recentDestinationData = recents
        ?.filter((item: IAttractionsSearch) => !!item.destination?.total_activities)
        .slice(0, 4);

    if (!recentDestinationData) {
        return null;
    }

    const recentDestinations = () => {
        return recentDestinationData.map((item) => {
            const {
                destination: recentDestination,
                category: recentDestinationCategory,
                dateRange,
            } = item;

            const { categories, url, name, slug, id } = recentDestination;

            const destinationCategories = (categories || '').split(',').map((item: string) => {
                const category = item.split(':');
                return {
                    id: category[0],
                    count: category[1],
                };
            });

            const currentCategoryData = destinationCategories.find(
                (item: Category) => item.id === recentDestinationCategory?.id
            );

            const currentCategory = categoriesOptions.find(
                (item) => item.id === currentCategoryData?.id
            )?.name;

            const destinationUrl: string =
                url ||
                attractionsUrl({
                    destination: slug,
                    category: recentDestinationCategory?.slug,
                    from: dateQueryString(new Date(dateRange.dateStart)),
                    to: dateQueryString(new Date(dateRange.dateEnd)),
                });

            return {
                destinationUrl,
                name,
                currentCategory,
                imageSrc: `${URL.image}/destination/${id}/center/100x100/ts`,
            };
        });
    };

    return (
        <S.Wrapper>
            <Container>
                <S.Container data-test-id="Recently_searched_destination">
                    <S.TitleBtnWrapper>
                        <S.Title>
                            <RecentSearchedIcon /> Your Recent Searches:
                        </S.Title>
                        <S.CardsWrapper>
                            {recentDestinations().map((dest, index) => {
                                return (
                                    <S.Card
                                        href={dest.destinationUrl}
                                        passHref
                                        key={index}
                                        count={recentDestinations().length}
                                        index={index}
                                    >
                                        <S.CardImg>
                                            <img
                                                src={dest.imageSrc}
                                                alt={
                                                    dest.name +
                                                    `${
                                                        dest.currentCategory
                                                            ? ' ' + dest.currentCategory
                                                            : ''
                                                    }`
                                                }
                                                width="100%"
                                                style={{
                                                    borderRadius: '100%',
                                                    objectFit: 'cover',
                                                }}
                                                height="100%"
                                            />
                                        </S.CardImg>
                                        <div>
                                            <S.CardName
                                                count={recentDestinations().length}
                                                index={index}
                                            >
                                                {dest.name}
                                            </S.CardName>
                                            <S.CardCategory>{dest.currentCategory}</S.CardCategory>
                                        </div>
                                    </S.Card>
                                );
                            })}
                        </S.CardsWrapper>
                    </S.TitleBtnWrapper>
                    <S.ImgBtnWrapper>
                        <S.ImageContainer />
                    </S.ImgBtnWrapper>
                </S.Container>
            </Container>
        </S.Wrapper>
    );
};

export default memo(RecentlySearchedDestination);

import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import binoculars from '@/shared/images/Images/binoculars.png';
import Link from 'next/link';

interface ICart {
    count: number;
    index: number;
}

export const Wrapper = styled.div`
    padding: 0 0 25px;

    ${Screen.ipad} {
        padding: 0 0 30px;
    }
    ${Screen.desktop} {
        padding: 30px 0 30px;
    }
`;

export const Container = styled.div`
    background-color: ${cssVars.bg_sand_dark3};
    padding: 32px 20px;
    border-radius: ${radius.small};

    ${Screen.ipad} {
        padding: 36px 30px;
    }
    ${Screen.desktop} {
        display: flex;
        justify-content: space-between;
        padding: 36px;
        margin: 0 auto;
    }
`;

export const TitleBtnWrapper = styled.div`
    > button {
        display: none;
    }

    ${Screen.desktop} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        > button {
            display: block;
            width: 250px;
            margin-bottom: 24px;
        }
    }
`;

export const Title = styled.h2`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.heading3}
    margin: 0 0 16px;
    display: flex;
    align-items: center;

    > svg {
        margin-right: 8px;
    }
`;

export const CardName = styled.div<ICart>`
    color: ${cssVars.fg_gray1_white};
    font-size: 16px;
    font-weight: 600;
    max-width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const CardCategory = styled.div`
    color: ${cssVars.fg_gray2_gray3};
    font-size: 14px;
    font-weight: 400;
`;

export const CardImg = styled.div`
    width: 50px;
    height: 50px;
    margin-right: 16px;
    flex: none;
`;

export const Card = styled(Link)<ICart>`
    background: ${cssVars.bg_white_blue};
    border-radius: 15px;
    box-shadow: ${cssVars.shadow_card_default};
    padding: 8px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;

    width: 100%;

    ${Screen.ipad} {
        padding: 15px;
        width: ${({ count, index }) => {
            if (count === 1 || count === 2) return '100%';
            if (count === 4) return 'calc(50% - 8px)';
            if (count === 3 && index !== count - 1) return 'calc(50% - 8px)';
            return '100%';
        }};
    }
`;

export const CardsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    z-index: 1;
    ${Screen.ipad} {
        gap: 12px;
    }
`;

export const ImgBtnWrapper = styled.div`
    display: none;
    ${Screen.desktop} {
        min-width: 46%;
        display: flex;
        align-items: center;
    }
`;

export const ImageContainer = styled.div`
    background-image: url(${binoculars.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 300px;
`;

export const noWrapBlock = styled.span`
    white-space: nowrap;
`;

import { memo, ReactElement } from 'react';

import { Gradient } from '@/screens/CommonPage/ui/commonPage/CardItem/cardItem/CardImage/CardImage.styles';
import { getFormattedDate } from '../../app/getFormattedDate';
import * as S from './BlogCard.styles';

type BlogCardProps = {
    title: string;
    date: string;
    cardLink: string;
    location?: string;
    imageSrc: string;
    imageAlt?: string;
    description?: string;
};

const BlogCardContainer = ({ title, date, cardLink, ...props }: BlogCardProps): ReactElement => {
    const { location, imageSrc, imageAlt } = props;

    return (
        <S.Container href={cardLink} passHref data-test-id="Blog_card">
            <S.ImgWrap>
                <Gradient />
                <S.Image
                    src={imageSrc}
                    alt={imageAlt || title}
                    loading="lazy"
                    width="500"
                    height="220"
                    data-test-id="Blog_card_img"
                />
                {Boolean(date) && <S.Date>{getFormattedDate(date)}</S.Date>}
            </S.ImgWrap>
            <S.Title data-test-id="Blog_card_title">{title}</S.Title>
            {Boolean(location) && (
                <S.Location>
                    <S.LocationImg />
                    {location}
                </S.Location>
            )}
        </S.Container>
    );
};

const BlogCard = memo(BlogCardContainer);
export { BlogCard };

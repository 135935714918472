import { TCardsContainerProps } from './CardsContainer.types';
import * as S from './CardsContainer.styles';

export const CardsContainer = ({
    title,
    description,
    linkHref,
    linkName,
    ...props
}: TCardsContainerProps) => {
    const isLong = title.length > 24;

    return (
        <S.Wrapper data-test-id="Cards">
            <S.CardsContainer>
                <S.Header isLong={isLong}>
                    <S.TitleContainer>
                        <S.Title>{title}</S.Title>
                        {description && <S.Description>{description}</S.Description>}
                    </S.TitleContainer>
                    <S.StyledLink
                        href={linkHref}
                        passHref
                        data-test-id="Cards_link"
                        isLong={isLong}
                    >
                        {linkName}
                        <S.Arrow />
                    </S.StyledLink>
                </S.Header>
                <S.Content>{props.children}</S.Content>
            </S.CardsContainer>
        </S.Wrapper>
    );
};

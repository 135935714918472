import styled, { DefaultTheme } from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { Commas } from '@/shared/images/svg/InlineSVG';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import Link from 'next/link';

export const Wrapper = styled.div`
    background-color: ${cssVars.bg_blue_dark3};
    width: 100%;
    margin: 25px 0;

    ${Screen.ipad} {
        margin: 30px 0;
    }
`;

export const Container = styled.div`
    padding: 40px 18px 50px;
    max-width: 1124px;
    overflow: hidden;
    ${Screen.ipad} {
        padding: 57px 30px 60px;
    }
    ${Screen.desktop} {
        padding: 51px 30px 60px;
        margin: 0 auto;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;

    ${Screen.ipad} {
        margin-bottom: 30px;
    }
`;

export const Title = styled.h2`
    color: ${Palette.white.normal};
    margin: 0;
    ${Text.Full.heading2}
    margin-right: 15px;
`;

export const Grid = styled.div`
    display: grid;
    grid-template: 1fr 1fr 1fr / 1fr 1fr;
    grid-gap: 12px;

    *:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    height: 492px;
    margin: 0 36px 30px 0;

    min-width: calc(100vw - 36px);

    ${Screen.ipad} {
        min-width: calc(100vw - 60px);
        height: 705px;
        grid-gap: 24px;
        margin: 0 60px 40px 0;
    }
    ${Screen.desktop} {
        min-width: 100%;
        max-width: 1064px;
        height: 470px;
        grid-template: 1fr 1fr / repeat(4, 1fr);

        *:first-child {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
        }

        *:nth-child(2) {
            grid-column-start: 3;
            grid-column-end: 5;
        }

        margin-bottom: 25px;
    }
`;

export const BtnContainer = styled.div`
    padding: 0 28px;
    font-family: 'Montserrat';

    ${Screen.desktop} {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        padding: 0;
    }
`;

export const Quote = styled.p`
    color: ${Palette.white.normal};
    ${Text.Desktop.normal.regular}
    margin: 0;
    padding-top: 12px;

    background-image: url(${Commas});
    background-repeat: no-repeat;
    display: none;

    *:last-child {
        color: ${Palette.gray.gray3};
        ${Text.Desktop.small.medium}
    }

    ${Screen.desktop} {
        display: block;
    }
`;

interface TGallery extends DefaultTheme {
    page: number;
}

export const Gallery = styled.div<TGallery>`
    transform: ${(props) => `translateX(-${props.page}00vw)`};
    transition: 0.5s ease 0s;
    width: auto;
    display: flex;
    user-select: none;

    ${Screen.fullWithPadding} {
        transform: ${(props) => `translateX(-${1124 * props.page}px)`};
    }
`;

export const InnerLink = styled(Link)`
    display: flex;
    width: 100%px;
    ${Screen.desktop} {
        width: 250px;
    }
`;

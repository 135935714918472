import * as S from './LandmarkCard.style';
import URL from '@/common/app/utils/urlsUtils';

type TLandmarkCardProps = {
    item: {
        id: string;
        name: string;
        slug: string;
        link?: string;
    };
    destinationSlug?: string;
    stateCode?: string;
    noMinHeight?: boolean;
    isTopSign?: boolean;
    minWidthFull?: boolean;
};

export const LandmarkCard = ({
    item,
    destinationSlug,
    stateCode,
    noMinHeight,
    isTopSign,
    minWidthFull,
}: TLandmarkCardProps) => {
    return (
        <S.CardContainer
            href={item.link || `/landmarks/${stateCode}/${destinationSlug}/${item.slug}/`}
            passHref
            noMinHeight={noMinHeight}
            minWidthFull={minWidthFull}
        >
            <S.ImgWrap>
                <S.Gradient />
                <S.Image src={`${URL.image}/landmark/${item.id}/700x600/${item.slug}.jpg`} />
            </S.ImgWrap>
            <S.Content>
                <S.Title isTop={isTopSign}>{item.name}</S.Title>
            </S.Content>
        </S.CardContainer>
    );
};

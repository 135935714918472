import styled, { css } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { radius } from '@/styles/Themes/Metrics';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import { Transition } from '@/styles/Themes/KeyFrames';
import Link from 'next/link';

export const CardContainer = styled(Link)<{ noMinHeight?: boolean; minWidthFull?: boolean }>`
    display: block;
    width: 100%;
    height: 100%;
    min-width: ${({ noMinHeight }) => (noMinHeight ? '0' : '240px')};
    min-height: ${({ noMinHeight }) => (noMinHeight ? '0' : '240px')};
    ${({ minWidthFull }) =>
        !!minWidthFull &&
        css`
            min-width: 100%;
        `}
    position: relative;
    flex-direction: column;
    border-radius: ${radius.small};
    background-color: ${cssVars.bg_white_dark3};
    ${Transition.normal};

    ${Screen.ipad} {
        min-height: ${({ noMinHeight }) => (noMinHeight ? '0' : '300px')};
    }

    &:hover {
        box-shadow: ${cssVars.shadow_card_hover};
        ${Transition.normal};
    }
`;

export const ImgWrap = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    border-radius: ${radius.small};
    overflow: hidden;

    ${Screen.ipad} {
        max-height: 300px;
    }
`;

export const Content = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 14px;
`;

export const Title = styled.div<{ isTop?: boolean }>`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 90%;
    white-space: nowrap;
    display: inline-block;
    border-radius: ${radius.small};

    ${({ isTop }) =>
        isTop
            ? css`
                  color: ${Palette.gray.gray1};
                  ${Text.Full.heading4};
                  background-color: ${Palette.white.normal};
                  margin: 0 8px 12px;
                  padding: 4px 10px 4px 14px;
              `
            : css`
                  color: ${Palette.white.normal};
                  ${Text.Full.large.semibold};
                  background-color: rgba(25, 33, 39, 0.7);
                  margin: auto 8px 12px;
                  padding: 2px 12px 0;
              `}
`;

export const Image = styled.img`
    width: 100%;
    object-fit: cover;
`;

export const Bottom = styled.div``;

export const BottomLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(13, 209, 197, 0.8);
    border-radius: 24px;
    color: ${Palette.white.normal};
    padding: 0 24px;
    height: 24px;
    ${Text.Full.medium.semibold}
`;

export const Gradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
        180deg,
        rgba(12, 11, 11, 0) 31.6%,
        rgba(0, 0, 0, 0.416) 73.45%
    );
`;

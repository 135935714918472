import dynamic from 'next/dynamic';
import { memo, ReactElement, useCallback, useMemo, useState } from 'react';

import { chunk } from '@/common/app/utils/dataUtils';
import { useMedia } from '@/common/service/hooks/useMedia';
import URL from '@/common/app/utils/urlsUtils';
import { Button } from '@/common/ui';
import DarkArrows from '@/common/ui/arrows/DarkArrows/DarkArrows';
import { DestinationCardProps } from '@/common/ui/images/DestinationCard';
import * as S from './ThrBestThingsToDo.styles';
import { TtheBestThingsToDo } from './ThrBestThingsToDo.types';

const DestinationCard = dynamic<DestinationCardProps>(
    () => import('@/common/ui/images/DestinationCard').then((module) => module.DestinationCard),
    { ssr: false }
);

export const TheBestThingsToDo = ({ popularDestinations }: TtheBestThingsToDo): ReactElement => {
    const media = useMedia('(min-width: 992px)');
    const size = media ? 4 : 3;
    const [isShowed, setIsShowed] = useState(popularDestinations.slice(0, size * 2));
    const [page, setPage] = useState(0);
    const [posXstart, setPosXstart] = useState(0);
    const [posYstart, setPosYstart] = useState(0);

    const current = useMemo(() => {
        return chunk(isShowed, size);
    }, [size, isShowed]);

    const onRight = useCallback(() => {
        if (isShowed.length !== popularDestinations.length) {
            setIsShowed(popularDestinations);
        }
        setPage(page + 1);
    }, [page, isShowed.length, popularDestinations]);

    const onLeft = useCallback(() => {
        setPage(page - 1);
    }, [page]);

    const rightOff = (page + 1) * size >= popularDestinations.length;
    const leftOff = page === 0;

    return (
        <S.Wrapper data-test-id="The_best_things_todo">
            <S.Container>
                <S.Header>
                    <S.Title>Explore the Top Things to do</S.Title>
                    <DarkArrows
                        onLeft={onLeft}
                        onRight={onRight}
                        leftOff={leftOff}
                        rightOff={rightOff}
                    />
                </S.Header>
                <S.Gallery
                    page={page}
                    onTouchStart={(event) => {
                        setPosXstart(event.changedTouches[0].clientX);
                        setPosYstart(event.changedTouches[0].clientY);
                    }}
                    onTouchEnd={(event) => {
                        const changedTouches = event.changedTouches[0];

                        const posYRange = Math.abs(changedTouches.clientY - posYstart);
                        const posXRange = Math.abs(changedTouches.clientX - posXstart);

                        if (posXRange > 30 && posXRange > posYRange) {
                            if (changedTouches.clientX > posXstart) {
                                if (!leftOff) {
                                    onLeft();
                                }
                            } else {
                                if (!rightOff) {
                                    onRight();
                                }
                            }
                        }
                    }}
                >
                    {current.map((slide) => {
                        return (
                            <S.Grid key={slide[0].id}>
                                {slide.map((dest) => {
                                    return (
                                        <DestinationCard
                                            cardLink={`/attractions/${dest.slug}/`}
                                            imageSrc={`${URL.image}/destination/${dest.id}/${dest.slug}.jpg`}
                                            imageAlt={dest.name}
                                            title={dest.name}
                                            experience={dest.experiences}
                                            key={dest.slug}
                                        />
                                    );
                                })}
                            </S.Grid>
                        );
                    })}
                </S.Gallery>
                <S.BtnContainer>
                    <S.Quote>
                        Somewhere, something incredible is waiting
                        <br />
                        to be known.
                        <br />
                        <br />
                        <span>—Carl Sagan</span>
                    </S.Quote>
                    <S.InnerLink href="/destination/" passHref prefetch={false}>
                        <Button
                            variant="primary"
                            onClick={() => {
                                return;
                            }}
                        >
                            See All Destinations
                        </Button>
                    </S.InnerLink>
                </S.BtnContainer>
            </S.Container>
        </S.Wrapper>
    );
};

export default memo(TheBestThingsToDo);
